@font-face
  font-family: "proxima_nova_rgregular"
  src: url('./fonts/proximanova-reg-webfont.eot')
  src: url('./fonts/proximanova-reg-webfont.eot?#iefix') format('embedded-opentype'),url('./fonts/proximanova-reg-webfont.woff') format('woff'),url('./fonts/proximanova-reg-webfont.ttf') format('truetype'),url('./fonts/proximanova-reg-webfont.svg#proxima_nova_rgregular') format('svg')
  font-weight: normal
  font-style: normal

@font-face
  font-family: "proxima_nova_rgregular"
  src: url('./fonts/proximanova-bold-webfont.eot')
  src: url('./fonts/proximanova-bold-webfont.eot?#iefix') format('embedded-opentype'),url('./fonts/proximanova-bold-webfont.woff') format('woff'),url('./fonts/proximanova-bold-webfont.ttf') format('truetype')
  font-weight: bold
  font-style: normal

html, body
  height: 100%

.container-flex
  display: flex
  flex-direction: row
  justify-content: center
  height: 100%
  a
    color: #009DDC
  .light_gray
    background: #fbfbfb
  .logo-section
    padding-top: 60px
    .logo-wrapper
      img
        padding-bottom: 15px
    .go-back
      font-size: 12px
      line-height: 20px
  h1
    font-size: 46px
    &:last-of-type
      margin-bottom: 40px
    &.blue
      color: #0096F4
  h4
    font-weight: normal
    margin: 5px 0 25px 0
  .bold
    font-weight: bold
  .left, .right
    width: 20%
    display: flex
    flex-direction: column
    align-items: center
    &.signup-page, &.login-page, &.logout-page, &.demo-page
      width: 50%
      align-items: flex-start
      .left-wrapper
        width: 440px
      .logo-wrapper
      &.logo-section
        align-items: flex-end
        padding-left: 105px
        padding-right: 105px
        box-sizing: border-box
    img.pipeline-logo
      width: 128px
      height: 24px
    .awards-carousel-wrapper
      display: flex
      flex-direction: column
    .awards-wrapper
      color: #6E717A
      justify-content: center
      margin: 30px 0px 35px
      h4
        text-align: center
      .awards
        font-size: 16px
        display: flex
        flex-direction: row
        gap: 24px
        justify-content: center

        img
          width: 93px
          height: 120px
  .center
    padding-top: 144px
    width: 50%
    display: flex
    flex-direction: column
    align-items: flex-start
    padding-left: 105px
    padding-right: 105px
    box-sizing: border-box
    img.pipeline-logo
      width: 128px
      height: 24px
    .logo-wrapper
      padding-bottom: 30px
    .pipeline-logo
      padding-bottom: 12px  
    &.signup-page, &.login-page, &.logout-page, &.demo-page
      padding-top: 0
    &.demo-page
      h4
        margin: 5px 0 5px 0
    &.forgot-password-page, &.email_verified_page, &.require_verified_email_page, &.get_started_page, &.reset-password-page
      padding-top: 60px
    .contact
      margin-top: 40px
    .form
      width: 440px
      .google-sign-up
        display: flex
        flex-direction: row
        justify-content: center
        align-items: center
        gap: 8px
        width: 438px
        height: 46px
        border: 1px solid #C5C5C5
        border-radius: 8px
        &:link, &:visited, &:hover, &:active
          color: #23222D
          text-decoration: none
        .text-block
          font-size: 16px
          padding-top: 5px
      label.error
        color: #FF2745
        margin: 10px 0 0 0
      input, select
        width: 440px
        height: 48px
        border: 1px solid #C5C5C5
        border-radius: 8px
        padding: 0
        display: block
        font-size: 16px
        line-height: 24px
        justify-content: center
        margin: 24px 0 0 0
        text-indent: 16px
        &[type=radio], &[type='checkbox']
          height: 12px
          width: 12px
        ::placeholder
          color: #A6A0A0
        &.hidden
          display: none
        &[type=submit]
          width: 440px
          background: linear-gradient(270deg, #5FCA96 0.18%, #32A26B 100.18%)
          border-radius: 8px
          color: #FEFFFE
          padding-top: 4px
          margin-top: 32px
          border: none
      .sales_team_size
        p
          margin-top: 24px
          font-size: 16px
        .options-wrapper
          display: flex
          flex-direction: row
          gap: 8px
          input
            margin: 0
          label
            margin-right: 12px
    .text
      p
        font-size: 16px
    .demo-form-padding
      padding: 150px 0px 0px 0px
    .sign-in-section, .sign-up-section
      width: 440px
      padding: 58px 0px 40px 58px
      line-height: 28px
      font-size: 20px
      text-align: right
      box-sizing: border-box
    .or
      display: flex
      flex-direction: row
      justify-content: center
      align-items: center
      gap: 8px
      width: 438px
      margin: 24px 0
      color: #A6A0A0
      .text
        padding-top: 4px
      .line
        border: 1px solid #A6A0A0
        border-bottom: 0
        width: 46%
    .terms
      font-size: 12px
      color: #6F6F6F
      a:link, a:visited, a:hover, a:active
        color: #6F6F6F
        text-decoration: underline

  .login-page, .logout-page, .reset-password-page
    .email-wrapper
      position: relative

      .avatar
        border-radius: 3px
        position: absolute
        right: 5px
        top: 5px

        img
          max-height: 40px

    .password-wrapper
      .contact
        a
          text-align: center
    .remember-me-wrapper
      display: flex
      flex-direction: row
      margin-top: 20px

      .left, .right
        display: block
        width: 50%

      .left
        input[type='checkbox'], label
          display: inline-block !important
          margin: 0

      .right
        text-align: right
    .mfa-wrapper
      display: none

  .forgot-password-page
    div.contact
      margin-top: 20px
      text-align: center

  .reset-password-page
    .password-rules-wrapper
      display: flex
      justify-content: space-between
      flex-wrap: wrap
      margin-top: 10px
      color: #979797
      font-size: 12px
      span
        flex: 0 0 50%
      p
        display: inline-block
        margin-left: 2px
        margin-bottom: 0
      .complete
        color: #13CE92
      &.validated
        .incomplete
          color: #d7453a

  .login-page, .logout-page, .reset-password-page, .forgot-password-page
    label.error
      color: #FF2745

  .carousel-container
    margin: 30px 120px 20px
    width: 276px
    display: flex
    align-self: center

    #people-carousel
      .carousel-indicators
        top: 370px
        left: 120px

        div
          display: inline-block
          width: 8px
          height: 8px
          background-color: #D6D6D6
          margin-left: 5px
          border-radius: 50%

          &.active
            background-color: #009DDC

      .carousel-inner
        position: relative
        overflow: visible
        .item
          img.person-image
            width: 260px
            height: 260px
            border-radius: 50%
            border: 8px solid #CDE5F3
        .quote-container
          position: absolute
          width: 300px
          padding: 20px
          top: 240px
          left: -32px
          background: rgba(255, 255, 255, 0.5)
          box-shadow: 0px 4px 20px rgba(142, 143, 152, 0.25)
          border-radius: 8px
          font-size: 14px

          .name
            font-weight: bold

          .title
            margin-top: 5px

          .quote
            margin-top: 10px
            font-style: italic

            img
              width: 24px
              height: 24px
              float: left
              margin-right: 5px

/* temporary fix - hide left section for smaller displays */
@media (max-width: 1250px)
  .container-flex
    > .left
      display: none !important
    > .right
      display: none !important
    .light_gray
      background-color: #fbfbfb
    .center
      width: 650px
      display: flex
      flex-direction: column
      align-items: center
      padding-left: 0px
      padding-right: 0px
      box-sizing: border-box

@media (min-width: 1250px)
  .container-flex
    > .left
      &.forgot-password-page, &.email_verified_page, &.require_verified_email_page, &.get_started_page, &.reset-password-page
        display: none !important
    > .right
      &.forgot-password-page, &.email_verified_page, &.require_verified_email_page, &.get_started_page, &.reset-password-page
        display: none !important
    .center
      &.forgot-password-page, &.email_verified_page, &.require_verified_email_page, &.get_started_page, &.reset-password-page
        padding-left: 220px
        padding-right: 220px
        width: auto

@media (max-width: 1249px) and (min-width: 376px)
  .container-flex
    > .left
      &.forgot-password-page, &.email_verified_page, &.require_verified_email_page, &.get_started_page, &.reset-password-page
        display: none !important
    > .right
      &.forgot-password-page, &.email_verified_page, &.require_verified_email_page, &.get_started_page, &.reset-password-page
        display: none !important
    .center
      &.forgot-password-page, &.email_verified_page, &.require_verified_email_page, &.get_started_page, &.reset-password-page
        padding-left: 105px
        padding-right: 105px
        width: auto
        align-items: flex-start

@media (max-width: 375px)
  .container-flex
    .center
      &.forgot-password-page, &.email_verified_page, &.require_verified_email_page, &.get_started_page, &.reset-password-page
        padding-left: 16px
        padding-right: 16px
        width: auto
        align-items: flex-start

@media (max-width: 500px)
  .container-flex
    .center
      .form
        width: 340px
        input, select
          width: 340px
          &[type=submit]
            width: 340px
        .google-sign-up
          width: 338px
      .sign-in-section, .sign-up-section
        width: 300px
      .or
        width: 338px

@media (max-width: 700px)
  body
    padding-left: 0
    padding-right: 0
